import * as React from "react"
import { Helmet } from 'react-helmet'
import styled, {createGlobalStyle} from 'styled-components'
import {theme} from '../theme'
import { graphql } from 'gatsby' 
import MultiArrow from '../components/multi-arrow'
import Logo from '../components/tall-talos'
import Seo from '../components/seo'

const GloblaStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    color: ${theme.color.dark};
    font-family: 'Rubik', sans-serif;
  }
`

// markup
const IndexPage = ({data}) => {
  // console.log(data)
  const content = data.allFile.edges[0].node.childMarkdownRemark.rawMarkdownBody
  const page = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const meta = {
    title: page.title,
    description: content,
    image: "/img/seo-image-1.png"
  }
  return (
    <Main>
      <GloblaStyle />
      <Seo meta={meta} />
      <Helmet>
        <title>{page.title}</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
        <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap" rel="stylesheet" />
      </Helmet>
      <Header>
        <Logo logoColor={theme.color.sea}/>
      </Header>
      <Body>
        <p>{content}</p>
        <a href={page.contact_link}>{page.contact_text} <MultiArrow /></a>
      </Body>
    </Main>
  )
}

export default IndexPage

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  position: relative;
  width: 100%;
  flex: 1 1 100%;
  background: ${theme.color.dark};
  & svg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Body = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 5vmin;
  
  & p {
    margin: 0 0 1em 0;
    max-width: 480px;
  }

  & a {
    color: ${theme.color.dark};
    text-decoration: none;
    &:hover {
      color: ${theme.color.verbillion};
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    & p {
      margin: 0;
    }
  }
`

export const query = graphql`
  query HomePageQuery1 {
    allFile(filter: {name: {eq: "home"}, sourceInstanceName: {eq: "data"}}) {
      edges {
        node {
          childMarkdownRemark {
            rawMarkdownBody
            frontmatter {
              contact_link
              contact_text
              title
            }
          }
        }
      }
    }
  }
`